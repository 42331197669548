<template>
  <v-dialog
    class="overflow-visible"
    no-click-animation
    max-width="450"
    :value="show"
    persistent
  >
    <close-button @click="close()" overflow />

    <v-card>
      <v-card-title>
        <span>Invite a Dietitian</span>
      </v-card-title>
      <v-card-text>
        <v-alert type="error" :value="!!error" border="left" text>
          {{ error }}
        </v-alert>

        <v-form ref="inviteForm" @submit.prevent="send()">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="Email Address"
            outlined
            dense
          ></v-text-field>
        </v-form>
        <v-btn
          @click="send()"
          :loading="status.inviting"
          color="primary"
          depressed
          block
        >Send Invitation</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      email: null,
      error: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.members.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('members', ['sendInvitation']),

    close() {
      this.$emit('close')
    },

    send() {
      if (this.error) this.error = null

      if (this.$refs.inviteForm.validate()) {
        Promise.all([this.sendInvitation(this.email)])
        .then((error) => {
          console.log(error)
          if (!error[0]) this.close()
          else this.error = error[0]
        })
      }
    }
  }
}
</script>