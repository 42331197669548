<template>
  <v-container fluid>
    <v-card class="box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <div class="d-flex justify-space-between" style="width:100%">
          <span class="font-weight-bold">Members</span>
          <div>
            <v-btn
              @click="toggleInvite(true)"
              color="primary"
              class="mb-1 mr-2"
              depressed
              small
            >
              Invite Dietitian
            </v-btn>

            <v-menu bottom left offset-y transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <!-- <v-list-item @click="$store.dispatch('members/sendEmail')" dense small>
                  <v-list-item-icon class="mr-2"><v-icon small>mdi-send</v-icon></v-list-item-icon>
                  <v-list-item-title>Send Email</v-list-item-title>
                </v-list-item> -->
                <v-list-item dense small @click="refreshList()">
                  <v-list-item-icon class="mr-2"><v-icon small>mdi-refresh</v-icon></v-list-item-icon>
                  <v-list-item-title>Refresh list</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <div class="d-flex flex-column align-end flex-md-row flex-lg-row align-md-center align-lg-center justify-lg-space-between" style="width:100%; gap: 0.5rem">
          <v-text-field
            v-model="search"
            @keydown.enter="searchMembers(search)"
            @click:clear="$store.commit('members/emptySearchResults')"
            placeholder="Search by name or email"
            class="font-weight-normal white"
            style="max-width: 1000px;width: 100%"
            hide-details
            clearable
            outlined
            rounded
            dense
          >
            <template #append>
              <v-btn
                @click="searchMembers(search)"
                :loading="status.searching"
                small
                icon 
              >
                <v-icon>mdi-account-search</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>

      </v-card-title>

      <v-card-text class="pa-0">
        <div class="d-flex px-5 pt-5" :style="{ width: '100%' }">
          <v-btn 
            @click="$router.push({ query: { v: 'published' } })"
            :color="$route.query.v == 'published' ? 'primary' : 'grey'"
            class="mr-2 text-none"
            outlined
            small 
          >Published</v-btn>
            
          <v-btn 
            @click="$router.push({ query: { v: 'pending' } })"
            :color="$route.query.v == 'pending' ? 'primary' : 'grey'"
            class="mr-2 text-none"
            outlined
            small 
          >
            <span>Pending</span>
            
            <v-icon v-if="pendingMembers.length" color="error" small right>mdi-alert-circle-outline</v-icon>
          </v-btn>
        </div>

        <v-card-text v-if="!filterMembers.length">
          <v-alert
            type="info"
            border="left"
            text
          >No users found.</v-alert>
        </v-card-text>

        <v-simple-table v-if="filterMembers.length && !status.getting">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Member Since</th>
                <th>Status</th>
                <th>APD/Registration Number</th>
                <th width="170">Referred by</th>
                <th width="170">Subscription</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="member in filterMembers" :key="member.id">
                <td>
                    <div class="d-flex align-center">
                      <UserPhoto :id="member.id" photoSize="thumb" :size="35" class="mr-2" />

                      <div class="font-weight-medium">
                        {{ member.firstName }} {{ member.lastName }}

                        <div v-if="member.apd" class="caption font-weight-medium blue-grey--text mt-n1">{{ member.apd }}</div>
                      </div>
                    </div>
                </td>
                <td class="blue-grey--text caption"> {{ member?.isStudent ? member.secondaryRole : startCase(member.role) }} </td>
                <td><a :href="`mailto:${member.email}`">{{ member.email }}</a></td>
                <td class="blue-grey--text text--lighten-2">{{ member.phone }}</td>
                <td class="caption blue-grey--text text--lighten-2">{{ member.registeredAt | formatDate }}</td>
                <td :class="member.status == 'active' ? 'success--text' : 'warning--text'">{{ member.status }}</td>
                <td v-if="member.role == 'dietitian'">
                  {{ member?.apd ? member.apd : 'none' }}
                </td>
                <td v-else-if="member.role == 'nutritionist' || member.role == 'naturopath'">
                  {{ member?.reg_num ? member.reg_nume : 'none' }}
                </td>
                <td v-else>
                  none
                </td>
                <td>
                  {{ member?.referred_by ? member.referred_by : 'none' }}
                </td>
                <td>
                  <user-subscription :user="member" />
                </td>
                <td width="10">
                  <v-menu bottom left offset-y transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        small
                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item v-if="member.status == 'pending'" @click="approveUser(member)">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-check</v-icon></v-list-item-icon>
                        <v-list-item-title>Approve</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeMemberRole(member)">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                        <v-list-item-title>Change Role</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="showUpdateDialog(member)">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-pencil-plus-outline</v-icon></v-list-item-icon>
                        <v-list-item-title>Update Credit</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="member.status !== 'pending'" @click="approveUser(member)">
                        <v-list-item-icon class="mr-2"><v-icon small>mdi-send</v-icon></v-list-item-icon>
                        <v-list-item-title>Resend Email</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="deleteMember(member)">
                        <v-list-item-icon class="mr-2"><v-icon color="red" small>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title> <span class="red--text">Delete User</span> </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <preloader v-if="status.getting" />

      </v-card-text>
      
      <v-card-actions class="blue-grey lighten-5" v-if="filterMembers.length">
        <v-btn
          v-if="filterMembers.length >= status.limit"
          @click="getMembers()"
          :loading="status.loadingMore"
          class="mr-2"
          color="secondary"
          depressed
          small
        >Load More</v-btn>

        <span class="caption">Showing {{ filterMembers.length }} {{ pluralize('member', filterMembers.length) }}</span>
      </v-card-actions>
    </v-card>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :showDialog="showDeleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDelete()"
      @confirmed="deleteConfirmed()"
    />

    <!-- CHANGE ROLE -->
    <change-role
      :show="changeRoleDialog"
      :user="toEdit"
      @close="closeRoleDialog()"
    />

    <!-- INVITE DIETITIAN DIALOG -->
    <invite
      :show="showInvite"
      @close="toggleInvite(false)"
    />

    <!-- UPDATE CREDIT DIALOG -->
    <UpdateCredit ref="updateCredit" :user="toUpdate" />
  </v-container>
</template>

<script>

import pluralize from 'pluralize'
import { startCase } from 'lodash'
import { mapState, mapActions } from 'vuex'

import invite from '@/components/InviteDialog'
import ConfirmDelete from '@/components/ConfirmDelete'
import ChangeRole from './components/ChangeRoleDialog'
import UserSubscription from '@/components/UserSubscription'
import UpdateCredit from './components/UpdateCreditDialog.vue'

export default {
  name: 'Members',

  metaInfo: {
    title: 'Members'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      pluralize,
      startCase,
      search: null,
      toEdit: null,
      toUpdate: {},
      toDelete: null,
      showInvite: false,
      deleteMessage: null,
      showDeleteDialog: false,
      changeRoleDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    invite,
    ChangeRole,
    UpdateCredit,
    ConfirmDelete,
    UserSubscription,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.members.status,
      members: state => state.members.members,
      pendingMembers: state => state.members.pendingMembers,
      searchResults: state => state.members.searchResults,
    }),

    filterMembers: function() {
      let members = this.$route.query.v == 'pending' ? this.pendingMembers : this.members
      if (this.searchResults.length) members = this.searchResults
      return members
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('members', [
      'getPendingMembers',
      'searchMembers',
      'approveUser',
      'getMembers',
      'delete',
    ]),

    refreshList() {
      this.$store.commit('members/emptyMembers')
      this.getMembers()
    },

    deleteMember(member) {
      this.showDeleteDialog = true
      this.deleteMessage = `Delete member "${member.firstName} ${member.lastName}"?`
      this.toDelete = member
    },

    closeDelete() {
      this.showDeleteDialog = false
    },

    deleteConfirmed() {
      Promise.all([
        this.delete(this.toDelete)
      ])
      .then(() => {
        this.closeDelete()
      })
    },

    changeMemberRole(user) {
      this.toEdit = user
      this.changeRoleDialog = true
    },

    closeRoleDialog() {
      this.toEdit = null
      this.changeRoleDialog = false
    },

    toggleInvite(showDialog) {
      if (showDialog) {
        this.showInvite = true
      }
      else {
        this.showInvite = false
      }
    },

    showUpdateDialog(user) {
      this.toUpdate = user
      this.$refs.updateCredit.showDialog()
    }
    
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    if (!this.status.firstLoad) this.getMembers()
    if (!this.status.pendingFirstLoad) this.getPendingMembers()
  }
}

</script>
