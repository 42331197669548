<template>
  <v-dialog
    :value="show"
    :max-width="450"
    no-click-animation
    persistent
  >
    <v-card v-if="user">
      <close-button @click="$emit('close')" overflow />

      <v-card-text class="pa-7">
        <v-card class="pa-3 mb-5" color="grey lighten-5" flat>
          <UserPhoto :id="user.id" photoSize="thumb" :size="35" class="mr-3"/>
          <span>{{ user.firstName }} {{ user.lastName }}</span>
        </v-card>

        <v-form ref="roleForm">
          <v-select
            v-if="user?.isStudent"
            v-model="secondaryRole"
            :items="roles1"
            item-text="name"
            itemvalue="value"
            label="Select Role"
            outlined
            dense
          ></v-select>
          <v-select
            v-else
            v-model="role"
            :items="roles"
            item-text="name"
            itemvalue="value"
            label="Select Role"
            outlined
            dense
          ></v-select>

          <v-btn 
            @click="updateUserRole()"
            :loading="status.updatingRole"
            color="primary"
          >Update</v-btn>
        </v-form>        
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    show: Boolean,
    user: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      role: null,
      secondaryRole: null,
      roles: [
        { name: 'Admin', value: 'admin' },
        { name: 'Dietitian', value: 'dietitian' },
        { name: 'Nutritionist', value: 'nutritionist' },
        { name: 'Naturopath', value: 'naturopath' },
        { name: 'Personal Trainer', value: 'personal_trainer' },
        { name: 'Member', value: 'member' },
      ],
      roles1: [
        { name: 'Student Fitness Professional', value: 'Student Fitness Professional'}, 
        { name: 'Student Nutrition Professional', value: 'Student Nutrition Professional'}
        ],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.members.status
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'user': function (val) {
      if (val) {
        this.role = this.user.role

        if(this.user?.isStudent)
          this.secondaryRole = this.user.secondaryRole
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('members', [
      'updateRole'
    ]),
    
    closeDialog() {
      this.$refs.roleForm.resetValidation()
      this.$emit('close')
    },

    updateUserRole() {
      let data = {
        user: this.user,
        role: this.user?.isStudent ? this.secondaryRole : this.role
      }

      Promise.all([
        this.updateRole(data)
      ])
      .then(() => {
        this.$emit('close')
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.user)
      this.role = this.user.role
  }
}
</script>